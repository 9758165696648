<template>
  <v-card flat class="pl-3">
    <v-card-text class="pa-0">
      <div class="text-subtitle-2 d-flex justify-space-between">
        <span>Reminders for {{ job.tournamentDayDate.format('ddd, MMM Do') }}</span>
        <v-btn
          color="color3"
          text x-small
          @click.stop="runNow = !runNow"
          :disabled="disabled"
          v-if="(inFuture(job.tournamentDayDate) && job.status !== 'Canceled') || (user && user.vbl)"
        >
        {{ job.status === 'Complete' ? 're-' : ''}}send now
        </v-btn>
      </div>
      <div>
        Scheduled for: {{ job.dtStart.format('ddd, MMM Do YY, h:mm:ss a') }}
      </div>
      <div>
        Status: {{ job.status }}
        <v-btn
          color="color3"
          text x-small
          @click.stop="expanded = !expanded"
          v-if="job.logs && job.logs.length > 0"
        >{{expanded ? 'hide' : 'view'}} logs</v-btn>
      </div>
    </v-card-text>
    <v-expand-transition>
      <v-card-text v-if="expanded">
        <div
          v-for="(log, i) in job.logs"
          :key="i"
        >
          {{ log }}
        </div>
      </v-card-text>
    </v-expand-transition>
    <v-expand-transition>
      <v-card-text v-if="runNow" class="text-center">
        <div class="title">Are you sure you want to run this job now?</div>
        <div>
          <v-btn
            color="success"
            class="mr-3"
            :loading="loading"
            @click.stop="resend"
          >Yes</v-btn>
          <v-btn color="error" @click.stop="runNow = false" :disabled="loading">No</v-btn>
        </div>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  props: ['job', 'disabled', 'league'],
  data () {
    return {
      expanded: false,
      runNow: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    inFuture (date) {
      const today = moment()
      return date.isSameOrAfter(today, 'day')
    },
    resend () {
      this.loading = true
      this.$VBL.tournament.league.gameReminders.resend(this.league.id, this.job.id)
        .then(r => {
          this.runNow = false
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
